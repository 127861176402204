<template>
    <div id="SignTemp-1" :class="theme || 'theme-red-CB0709'" v-title :data-title="registerInfo.title && registerInfo.title">
        <login v-if="show"></login>
        <div class="SignTemp-1">
            <!--            <img class="banner" src="../../../assets/images/VoteTemp-1/banner.png" alt="">-->
            <img class="banner" :src="registerInfo.themeImg" alt="">
            <!--            活动规则-->
            <div class="ruleCon height-auto">
                <div class="starTop">
                    <img class="star" src="../../../assets/images/VoteTemp-1/star.png" alt="">
                    <p class="starTitle">{{registerInfo.title}}</p>
                </div>
<!--                <div class="title">{{ registerInfo.title }}</div>-->
                <div class="spent" v-show="registerInfo.registFee">
                    ¥{{ registerInfo.registFee || '' }}
                </div>
                <div class="ruleItem" v-for="item in ruleList">
                    <img class="strTime" :src="item.img" alt="">
                    <p class="ruleP1">{{ item.ruleP1 }}</p>
                    <p class="ruleP2">{{ item.ruleP2 }}</p>
                </div>
<!--                <div class="sign-number">-->
<!--                    已报名-->
<!--                    <span>{{ registerInfo.registCount }}</span>-->
<!--                    /{{ registerInfo.registLimit || '无限制'}}-->
<!--                </div>-->
            </div>
            <div class="ruleCon">
                <div class="ruleTop">
                    <img class="arrow" src="../../../assets/images/VoteTemp-1/leftArrows.png" alt="">
                    <span class="rule">活动规则</span>
                    <img class="arrow" src="../../../assets/images/VoteTemp-1/rightArrows.png" alt="">
                </div>
                <div class="html text-content" v-html="registerInfo.introduction">

                </div>
            </div>
            <div class="bottom-btn"  v-show="registerInfo.registStatus !== '2' && registerInfo.registStatus !== '3'">
                <div v-if="registerInfo.canRegistFlag === '1' || registerInfo.canRegistFlag === '6'" @click="toSign">
                    {{registerInfo.canRegistFlag === '1' ? '立即报名' : '余额不足支付余额'}}
                </div>
                <div v-else class="disable-btn">{{ registerInfo.canRegistFlag | formatterCanRegistFlag}}</div>
            </div>
            <div class="bottom-btn"  v-show="registerInfo.registStatus === '2' || registerInfo.registStatus === '3'">
                <div class="disable-btn">之前订单有问题，请联系管理员</div>
            </div>
        </div>
    </div>
</template>

<script>
import login from '../../../components/login'
import {getRegisterInfo} from '../../../assets/api/sign'
import wxShare from '../../../assets/js/wx-share'
import {isWeixin, loginWx, getIsApp, WXauthorizeWx} from '../../../assets/js/wx-authorize'
import {formatterCanRegistFlag} from "../../../assets/js/untils";
import {Toast} from "vant";
import lodash from 'lodash'
import {Decrypt, Encrypt, generatekey, getKey} from "../../../assets/js/aes";
import {rsaPublicData} from "../../../assets/js/rsa";

export default {
    name: "SignTemp-1",
    components: {
        login,
    },
    data() {
        return {
            active: 0,
            icon: {
                inactive: 'https://img.qiluyidian.net/Y7hrRH_1638950109936.png',
                active: 'https://img.qiluyidian.net/spWBrk_1638950126299.png',
            },
            icon1: {
                inactive: 'https://img.qiluyidian.net/fWyrJW_1638950363085.png',
                active: 'https://img.qiluyidian.net/jSe8Jt_1638950385900.png',
            },
            icon2: {
                inactive: 'https://img.qiluyidian.net/WPwZ2S_1638950406185.png',
                active: 'https://img.qiluyidian.net/k7s24i_1638950419884.png',
            },
            registerInfo: {},
            //    规则列表
            ruleList: [
                {
                    img: 'https://img.qiluyidian.net/nEZwA2_1638955743750.png',
                    ruleP1: '报名开始',
                    ruleP2: '2021-08-12',
                },
                {
                    img: 'https://img.qiluyidian.net/nEZwA2_1638955743750.png',
                    ruleP1: '报名结束',
                    ruleP2: '2021-08-12',
                },
                {
                    img: 'https://img.qiluyidian.net/K7QrjA_1638955762393.png',
                    ruleP1: '活动开始',
                    ruleP2: '2021-08-12',
                },
                {
                    img: 'https://img.qiluyidian.net/K7QrjA_1638955762393.png',
                    ruleP1: '活动结束',
                    ruleP2: '2021-08-12',
                },
                {
                    img: 'https://img.qiluyidian.net/Kt68Bj_1638955779662.png',
                    ruleP1: '联系人',
                    ruleP2: '12345678999',
                },
                {
                    img: 'https://img.qiluyidian.net/Kt68Bj_1638955779662.png',
                    ruleP1: '活动地址',
                    ruleP2: '每个用户每天可投10票',
                },
            ],
            // 数据访问量啥啥啥
            count: {},
            //距离结束的时间
            intervalToEnd: '',
            second: '',
            min: '',
            time: '',
            day: '',
            voteGroupList: [],
            //选项信息分页
            pageNum: 1,
            flag: false,
            noMore: false,
            isLoad: true,
            requestFlag: true,
            //接口返回接收
            groupId: '',
            //排行榜页码
            pageNo: 1,
            moreFlag: true,

            //    介绍隐藏
            showIntro: false,
            //    排行隐藏
            showRank: false,
            rankList: [],
            //    投票隐藏
            showVote: false,
            searchCon: '',
            voteList: [],
            show: false,
            id: '1'
        }
    },
    beforeDestroy() {
    },
    created() {
        // this.getParams()
    },
    mounted() {
        setTimeout(async () => {
            this.getParams()
        }, 0)
        // if (!this.token) {
        //     if (isWeixin()) {
        //         loginWx()
        //     } else {
        //         this.show = false
        //     }
        // }
    },
    methods: {
        getParams() {
            this.id = this.$route.query.signId
            if (getIsApp()) {
                if(this.isFlutter) {
                    window.h5hongbaoPayCallback = (res) => {
                        res = JSON.parse(res)
                        // state  ====> "ok"/"fail"
                        if (res.state == "ok") {
                            this.getRegisterInfo()
                        } else {
                            this.getRegisterInfo()
                        }
                    }

                    window.h5SendAppToken = (data) => {
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                            // this.getParams()
                            this.getRegisterInfo()
                        }
                    }
                    window.flutter_inappwebview.callHandler('h5GetApptoken')
                }
                this.$bridge.registerHandler('h5hongbaoPayCallback', res => {
                    res = JSON.parse(res)
                    // state  ====> "ok"/"fail"
                    if (res.state == "ok") {
                        this.getRegisterInfo()
                    } else {
                        this.getRegisterInfo()
                    }
                })
                this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                    console.log('sssssss', data)
                    if (data) {
                        this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        // this.getParams()
                        this.getRegisterInfo()
                    }
                })
                this.$bridge.callHandler('h5GetApptoken')

            } else if (!this.token) {
                this.getRegisterInfo()
                // this.getParams()
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = true
                }
            } else {
                this.getRegisterInfo()
                // this.getParams()
            }
        },
        getLogin() {
            if (getIsApp()) {
                if(this.isFlutter) {
                    window.h5SendAppToken = (data) => {
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        }
                    }
                    window.flutter_inappwebview.callHandler('h5GetApptoken')
                }
                this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                    console.log(data)
                    if (data) {
                        this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        // this.getParams()
                    }
                })
                this.$bridge.callHandler('h5GetApptoken')

            } else if (!this.token) {
                // this.getParams()
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = true
                }
            } else {
                // this.getParams()
            }
        },
        async getRegisterInfo() {
            console.log(window.localStorage.getItem('token'))
            let key = generatekey(16)
            let {data, code} = await getRegisterInfo(this.id, encodeURIComponent(rsaPublicData(key)))
            if(code == 200) {

                this.registerInfo = data
                this.ruleList[0].ruleP2 = data.registStartTime
                this.ruleList[1].ruleP2 = data.registEndTime
                this.ruleList[2].ruleP2 = data.actStartTime
                this.ruleList[3].ruleP2 = data.actEndTime
                this.ruleList[4].ruleP2 = Decrypt(data.actTel, key)
                this.ruleList[5].ruleP2 = data.address
                // this.ruleList[0].ruleP2 = `${data.registStartTime.split(' ')[0]} - ${data.registEndTime.split(' ')[0]}`
                // this.ruleList[1].ruleP2 = `${data.actStartTime.split(' ')[0]} - ${data.actEndTime.split(' ')[0]}`
                // this.ruleList[2].ruleP2 = data.actTel
                // this.ruleList[3].ruleP2 = data.address
                // this.$set(ruleList[0], 'ruleP2', `${data.registStartTime}-${data.registEndTime}`)
                // this.$set(ruleList[1], 'ruleP2', `${data.actStartTime}-${data.actEndTime}`)
                // this.$set(ruleList[2], 'ruleP2', data.actTel)
                // this.$set(ruleList[3], 'ruleP2', data.address)
                if(isWeixin()) {
                    let {title, weShareSummary, weShareImg} = data
                    wxShare.loadWxConfig(title, weShareSummary, window.location.href, weShareImg)
                }
            }

        },
        //   活动评选数据 (访问量啥啥啥)
        // getVoteStatistInfo() {
        //     let params = {
        //         "signId": this.id,
        //     };
        //     getVoteStatistInfo(params).then(res => {
        //         console.log(res.data)
        //         if (res.code == 200) {
        //             this.count = res.data
        //         }
        //     }).catch(err => {
        //     })
        // },
        // 进入个人详情页
        toSign() {
            if (!this.token) {
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = true
                }
            }else {
                // 判断是否有钱可以扣款
                console.log(this.registerInfo.canRegistFlag)
                if(this.registerInfo.canRegistFlag === '6') {
                    console.log(getIsApp)
                    if(getIsApp()) {
                        this.payment()

                    } else {
                        this.$toast('请下载齐鲁壹点充值余额')
                    }
                    // if()
                }else {
                    this.$router.push({
                        path: '/sign1-details',
                        query: {
                            id: this.id,
                            registFee: this.registerInfo.registFee
                        }
                    })
                }

            }
        },
        async payment() {
            console.log('pay')
            if(this.isFlutter) {
                window.flutter_inappwebview.callHandler('h5HandOutRedPackNew', {
                    money: this.registerInfo.diff
                })
            }
            this.$bridge.callHandler('h5HandOutRedPackNew', {
                money: this.registerInfo.diff
            })
            // let {code, msg} = await payment()
            // if(code === 200) {
            //
            // }
        }
        //倒计时时间转换
    },
    computed: {
        theme() {
            return this.$store.getters.getTheme
        },
        token() {
            return this.$store.getters.getToken
        },
        loginDialog() {
            return this.$store.getters.getLoginDialog
        },
        isFlutter() {
            return this.$store.getters.getFlutter
        }
    },
    watch: {
        loginDialog(val) {
            console.log('open', val)
            if(val) {
                this.show = true
            }
        },
        token(val) {
            if (val) {
                this.getRegisterInfo()

            }
        },
        isFlutter() {
            this.getParams()
        }
    },
    filters: {
        formatterCanRegistFlag
    }

}
</script>

<style scoped lang="less">
@import "../../../assets/css/theme";

.module-theme(@theme, @tag) {
    .SignTemp-1 {
        max-width: 375px;
        margin: auto;
        min-height: 885px;
        padding-bottom: 126px;
        background-image: url("../../../assets/images/VoteTemp-1/bg.png");
        background-size: 100% auto;
        background-repeat: no-repeat;

        .banner {
            width: 100%;
            //max-width: 375px;
            display: block;
            height: auto;
        }

        /*致敬劳动者评选活动*/

        .starCon {
            width: calc(100% - 30px);
            min-height: 175px;
            background: #FFFFFF;
            border-radius: 2px;
            margin: 20px 15px;
            padding-bottom: 12px;

            .starTop {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                min-height: 30px;
                margin: 0 10px;
                border-bottom: 1px solid #D4D4D4;
                line-height: 30px;
                font-weight: bold;
                padding-top: 12px;

                .star {
                    width: 11px;
                    height: 16px;
                    display: block;
                    margin: 4px 7px 15px 0;
                    //margin: 15px 7px 15px 0;
                }

                .starTitle {
                    line-height: 30px;
                }
            }

            .voteNumbers {
                height: 66px;
                background: @theme;
                border-radius: 4px;
                margin: 15px 10px;
                display: flex;
                justify-content: space-between;

                .voteNumCon {
                    width: 108px;
                    height: 42px;
                    margin: 11px 0;
                    border-right: 1px solid #D8D8D8;
                    color: #FFF;

                    .p1 {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .p2 {
                        font-size: 13px;
                        line-height: 18px;
                    }
                }

                .voteNumCon:nth-child(3) {
                    border-right: none;
                }
            }

            .numP {
                color: @theme;
            }
        }

        /*活动规则*/

        .ruleCon {
            min-height: 144px;
            background: #FFFFFF;
            border-radius: 2px;
            margin: 0 15px 20px;

            .ruleTop {
                display: flex;
                justify-content: center;
                padding-top: 15px;

                .rule {
                    color: @theme;
                    height: 22px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 22px;
                }

                .arrow {
                    width: 31px;
                    height: 14px;
                    display: block;
                    margin: 4px 5px;
                }
            }

            .ruleItem {
                display: flex;
                justify-content: flex-start;
                font-size: 14px;
                line-height: 20px;
                margin: 8px 14px;
                min-height: 20px;
                flex-wrap: wrap;

                .strTime {
                    width: 14px;
                    height: 14px;
                    display: block;
                    margin: 3px 6px 3px 0;
                }

                .ruleP1 {
                    color: #333;
                    margin-right: 10px;
                }

                .ruleP2 {
                    color: #666;
                    text-align: left;
                    //padding-left: 18px;
                    box-sizing: border-box;
                }

                .ruleP3 {
                    color: #666;
                    margin-left: 20px;
                }
            }
        }

        .html {
            width: 95%;
            margin: 10px auto;
            text-align: left;
            word-break: break-all;
            font-size: 12px;
        }

        /*底图*/

        .footBg {
            max-width: 375px;
            margin: auto;
            width: 100%;
            height: 156px;
            background-image: url("../../../assets/images/VoteTemp-1/bottomBg.png");
            background-size: 100% 100%;
            position: fixed;
            bottom: 0;
            left: calc(50% - 187.5px);
            z-index: -2;

            .footRule {
                width: 100%;
                font-size: 12px;
                color: #666666;
                line-height: 17px;
                text-align: center;
                margin-top: 50px;
            }
        }

        /*tabbar*/

        .foot {
            .van-tabbar--fixed {
                max-width: 375px !important;
                margin: 0 calc(50% - 187.5px);
                padding-bottom: 20px !important;
            }
        }

        .tabCon {
            max-width: 375px;
            height: auto;
            margin: 0 15px;
            /*活动介绍*/

            .introCon {
                /*height: 621px;*/
                height: auto;
                padding-bottom: 20px;
                background: #FFFFFF;
                border-radius: 2px;
                font-size: 13px;
                color: #666666;
                line-height: 18px;

                .introTop {
                    display: flex;
                    justify-content: center;
                    padding-top: 15px;

                    .intro {
                        color: @theme;
                        height: 22px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 22px;
                    }

                    .arrow {
                        width: 31px;
                        height: 14px;
                        display: block;
                        margin: 4px 5px;
                    }
                }

                .introItem1 {
                    margin: 0 15px;

                    .introItem {
                        display: flex;
                        justify-content: flex-start;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 22px;
                        margin: 25px 0 5px 0;

                        .dot {
                            width: 8px;
                            height: 8px;
                            display: block;
                            margin: 6px 5px 6px 0;
                        }
                    }

                    .introP1 {
                        margin-left: 13px;
                        text-align: left;
                    }
                }

                .introItem2 {
                    margin: 0 15px;

                    .introItem {
                        display: flex;
                        justify-content: flex-start;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 22px;
                        margin: 21px 0 5px 0;

                        .dot {
                            width: 8px;
                            height: 8px;
                            display: block;
                            margin: 6px 5px 6px 0;
                        }
                    }

                    .introP1 {
                        margin-left: 13px;
                        text-align: left;
                    }

                    .introP2 {
                        font-size: 12px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 17px;
                        margin: 10px 0 4px;
                    }
                }
            }

            /* 排行榜*/

            .rankCon {
                min-height: 386px;
                height: auto;
                background: #FFFFFF;
                border-radius: 2px;
                font-size: 13px;
                color: #666666;
                line-height: 18px;
                padding-bottom: 10px;

                .introTop {
                    display: flex;
                    justify-content: center;
                    padding-top: 15px;

                    .intro {
                        color: @theme;
                        height: 22px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 22px;
                    }

                    .cup {
                        width: 22px;
                        height: 22px;
                        display: block;
                        margin-right: 4px;
                    }
                }

                .introItem {
                    padding: 0 15px 0 11px;
                    height: 36px;
                    font-size: 14px;
                    color: #333333;
                    line-height: 36px;
                    display: flex;
                    justify-content: space-between;
                    margin: 10px 0;

                    .introItem1 {
                        display: flex;
                        justify-content: flex-start;

                        .cupImg {
                            width: 28px;
                            height: 28px;
                            display: block;
                            margin-top: 3px;
                            margin-right: 33px;
                            line-height: 28px;
                            color: @theme;
                            font-weight: bold;
                            text-align: center;
                        }

                        .cupName {
                            max-width: 208px;
                        }
                    }

                    .cupNum {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                .more {
                    margin-top: 30px;
                }
            }

            /*投票*/

            .voteCon {
                min-height: 386px;
                border-radius: 2px;
                font-size: 13px;
                color: #666666;
                line-height: 18px;

                .voteTop {
                    display: flex;
                    justify-content: flex-start;
                    /*margin: 0 15px;*/
                    position: relative;
                    height: 34px;
                    line-height: 34px;

                    .searchImg {
                        width: 22px;
                        height: 23px;
                        position: absolute;
                        left: 2px;
                        top: 6px;
                    }

                    .input {
                        width: 80%;
                        border: none;
                        outline: none;
                        font-size: 14px;
                        color: #999999;
                        padding: 0 24px;
                    }

                    .send {
                        width: 20%;
                        background-color: @theme;
                        color: #fff;
                        text-align: center;
                    }
                }

                .introItem {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    justify-items: center;
                    margin-top: 20px;

                    .voteItem {
                        width: 170px;
                        height: 293px;
                        background: #FFFFFF;
                        border-radius: 2px;
                        margin: 0 5px 6px 0;
                        position: relative;

                        .topNum {
                            background-image: url("../../../assets/images/VoteTemp-1/topRank.png");
                            background-repeat: no-repeat;
                            background-size: 100%;
                            width: 34px;
                            height: 23px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            font-size: 12px;
                            color: #FFFFFF;
                            line-height: 24px;
                            padding-left: 8px;
                            text-align: left;
                        }

                        .play {
                            width: 29px;
                            height: 29px;
                            display: block;
                            position: absolute;
                            left: 10px;
                            top: 143px;
                        }

                        .voteHead {
                            width: 170px;
                            height: 188px;
                            border-radius: 2px;
                            object-fit: cover;
                        }

                        .voteNum {
                            color: @theme;
                            font-size: 16px;
                            line-height: 22px;
                            margin-top: 8px;
                        }

                        .voteTitle {
                            font-size: 12px;
                            color: #333333;
                            line-height: 17px;
                            margin-top: 2px;
                        }

                        .voteImg {
                            width: 70px;
                            height: 24px;
                            margin-top: 13px;
                        }
                    }

                    .voteItem:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .title {
        padding-top: 15px;
        color: rgba(51, 51, 51, 1);
        word-break: break-all;
    }

    .spent {
        font-size: 16px;
        color: @theme;
        margin: 8px 14px;
        text-align: left;
    }

    .sign-number {
        margin: 8px 14px;
        text-align: left;
        font-size: 16px;
        padding-bottom: 20px;


        > span {
            color: @theme;
        }
    }

    .bottom-btn {
        width: 100%;
        height: 60px;
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;

        > div {
            max-width: 375px;
            background: @theme;
            color: white;
            width: 90%;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .disable-btn {
            background: #999999;
        }
    }

    .height-auto {
        height: auto;
    }

    .starTop {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 30px;
        margin: 0 10px;
        border-bottom: 1px solid #D4D4D4;
        line-height: 30px;
        font-weight: bold;
        padding-top: 12px;

        .star {
            width: 11px;
            height: 16px;
            display: block;
            margin: 4px 7px 15px 0;
            //margin: 15px 7px 15px 0;
        }

        .starTitle {
            text-align: left;
            line-height: 30px;
            word-break: break-all;
        }
    }
    // 其他样式也如此
}
</style>
