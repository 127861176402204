import axios from 'axios'

let imgDomain = 'https://img.qiluyidian.net/'

export async function uploadImage(fileInfo) {
    return new Promise((resolve, reject) => {
        axios.post("https://gateway.ql1m.com/gateway/editor/manage/common/back/getQiniuUptoken.do").then((res) => {
            let token = res.data.data.uptoken;

            // 此时可以自行将文件上传至服务器
            console.log(fileInfo)
            // this.cover = file.content
            let {file} = fileInfo
            let timeKey = new Date().getTime() + file.name
            // console.log(fileInfo)
            let fileData = new FormData()
            fileData.append('token', token)
            fileData.append('key', timeKey)
            fileData.append('file', file)
            axios.post('https://up.qiniup.com/', fileData).then(data => {
                let {data: {key, hash}} = data
                if (!key) {
                    this.$toast('网络错误,请稍后重试')
                    return false
                }
                resolve(imgDomain + key)
            })
        })
    })

}